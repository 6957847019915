import Service from '@/helpers/service'

export default {

    get() {
        return Service().get('DatabaseBackup');
    }, 
    update(id, params) {
        return Service().post('DatabaseBackup/update/'+id, params);
    },
}