<template>
    <div>
        <div v-if="authUserPermission['database-backup-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('database_backup') }}</h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="danger" @click="update(databaseBackup.id)" :disabled="btnSave.onLoading" v-if="authUserPermission['database-backup-update']">
                                    <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('update') }}</span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <base-input :name="tt('backup_name')" :label="tt('backup_name')" :placeholder="tt('backup_name')" v-model="databaseBackup.backup_name" rules="required"></base-input>

                        <base-input :name="tt('backup_time')" :label="tt('backup_time')" :placeholder="tt('backup_time')" rules="required">
                            <el-select class="select-danger" v-model="databaseBackup.backup_time" :placeholder="tt('choose_status')">
                                <el-option class="select-danger" value="everyMinute" label="Every Minute" key="everyMinute"></el-option>
                                <el-option class="select-danger" value="everyFiveMinutes" label="Every Five Minutes" key="everyFiveMinutes"></el-option>
                                <el-option class="select-danger" value="everyTenMinutes" label="Every Ten Minutes" key="everyTenMinutes"></el-option>
                                <el-option class="select-danger" value="everyFifteenMinutes" label="Every Fifteen Minutes" key="everyFifteenMinutes"></el-option>
                                <el-option class="select-danger" value="everyThirtyMinutes" label="Every Thirty Minutes" key="everyThirtyMinutes"></el-option>
                                <el-option class="select-danger" value="hourly" label="Hourly" key="hourly"></el-option>
                                <el-option class="select-danger" value="hourlyAt(17)" label="HourlyAt(17)" key="hourlyAt(17)"></el-option>
                                <el-option class="select-danger" value="daily" label="Daily" key="daily"></el-option>
                            </el-select>
                        </base-input>

                        <base-input :name="tt('backup_path')" :label="tt('backup_path')" :placeholder="tt('backup_path')" v-model="databaseBackup.backup_path" rules="required"></base-input>

                        <base-input :name="tt('backup_maximum_of_files')" :label="tt('backup_maximum_of_files')" :placeholder="tt('backup_maximum_of_files')" v-model="databaseBackup.backup_maximum_of_files" rules="required"></base-input>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import databaseBackup from '@/services/setting/databaseBackup.service';

    export default {        
        data() {
            return {   
                btnSave: {
                    onLoading: false
                },
                databaseBackup: {} 
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, databaseBackup.get()).onSuccess(function(response) {
                    context.databaseBackup = response.data.data[0];
                }).onError(function(error) {
                    if (error.response.status) {
                        context.databaseBackup = {}
                    }
                }).call()
            },
            update(id) {
                console.log(id)
                let context = this;
                let api = null;

                this.btnSave.onLoading = true;
                Api(context, databaseBackup.update(this.databaseBackup.id, this.databaseBackup)).onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                }).call();
            },
        }   
    };
</script>
<style></style>
